import React from "react";
import Tag from "./Tag.jsx";
const TagHolder = React.forwardRef((props, ref) => {
  let shownTags = [];
  let letterCount = 0;
  if (props.isCropped) {
    for (let i = 0; i < props.tags.length; i++) {
      letterCount += props.tags[i].length;
      if (i == 0 || letterCount < 24) {
        shownTags.push(props.tags[i]);
      }
    }
  } else {
    shownTags = props.tags;
  }
  return (
    <div className={`center-react-holder ${props.class}`} ref={ref}>
      {shownTags.map((tag, iter) => {
        return <Tag tag={tag} key={iter} />;
      })}
    </div>
  );
});
export default TagHolder;
