import React from "react";
let contactDetails = [
  { text: "email", link: "mailto:apennings@outlook.com.au" },
  { text: "linkedIn", link: "https://www.linkedin.com/in/allan-pennings/" },
  { text: "gitHub", link: "https://github.com/AMPennings/AMPennings.git" },
];

function ContactOptions(prop) {
  return (
    <a href={prop.link} className="contact-links">
      {prop.text}
    </a>
  );
}

function ContactDeets() {
  return (
    <div className="contact-details">
      {contactDetails.map((loc, iter) => {
        return <ContactOptions text={loc.text} link={loc.link} key={iter} />;
      })}
    </div>
  );
}

export default ContactDeets;
