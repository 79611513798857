import React from "react";
import TagHolder from "./TagHolder";
function ProjectCard(props) {
  return (
    <div className="project-card">
      <img
        className="project-image"
        src={props.img_src}
        alt={props.img_desc}
        // ref={imgRef}
      />
      <h1 className="project-heading">{props.heading}</h1>
      <p className="project-body">{props.description}</p>
      <a  className="project-button" href={props.url}>Check It Out</a>
      <TagHolder
        tags={props.skill_list}
        isCroppe={false}
        class={"project-tag-holder"}
      />
    </div>
  );
}
export default ProjectCard;
