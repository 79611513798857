import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import parse, { domToReact } from 'html-react-parser'

function BlogDetail() {
  const { filename } = useParams() // Get the filename from the URL
  const [post, setPost] = useState(null)

  useEffect(() => {
    // Decode the filename to handle any special characters
    const decodedFilename = decodeURIComponent(filename)
    console.log(decodedFilename)
    fetch(`/posts/${decodedFilename}`)
      .then((res) => res.json())
      .then((data) => setPost(data))
      .catch((err) => console.error('Error loading post:', err))
  }, [filename]) // Re-fetch if filename changes

  const transformHtml = (htmlContent) => {
    return parse(htmlContent, {
      replace: (domNode) => {
        if (domNode.name === 'h1') {
          // Add class to h1 elements
          return <h1 className="h1-blog">{domToReact(domNode.children)}</h1>
        }
        if (domNode.name === 'h2') {
          // Add class to h2 elements and insert an <hr> before each <h2>
          return (
            <>
              <hr className="pageDividerBlog" />
              <h2 className="h2-blog">{domToReact(domNode.children)}</h2>
            </>
          )
        }
        if (domNode.name === 'p') {
          // Add class to p elements
          return <p className="p-blog">{domToReact(domNode.children)}</p>
        }
        if (domNode.name === 'img') {
          // Add class to img elements
          return (
            <img
              className="img-blog"
              src={domNode.attribs.src}
              alt={domNode.attribs.alt || ''}
            />
          )
        }
        return domNode
      },
    })
  }

  if (!post) return <div>Loading...</div>

  return (
    <div className="blog-detail">
      <Link to="/my-blog" className="blog-return-rhs">
        ← Return to Post List
      </Link>
      <div className="blog-post">
        <div>{transformHtml(post.content)}</div>
        <Link to="/my-blog" className="blog-return">
          ← Return to Post List
        </Link>
      </div>
    </div>
  )
}

export default BlogDetail
