import React from "react";
import Card from "./Card.js";
import SectionHeading from "./Section_heading.jsx";
import cardDeck from "../Card_deck.js";
import PopUp from "./PopUp.jsx";
import SkillDeckButtons from "./SkillDeckButtons.jsx";
const SkillSection = React.forwardRef((props, ref) => {
  let [deckKey, setDeckKey] = React.useState("soft-skills");
  let [popupCard, setPopUpCard] = React.useState(
    cardDeck["soft-skills"][0]
  );
  let [popUpDisplay,setPopUpDisplay]=React.useState('none')
  let popUpRef = React.useRef();
  let divRef = React.useRef();
  return (
    <div id="skills-div" className="section-div" ref={ref}>
      {/* <hr /> */}
      <SectionHeading title="My Skills" paddingTop="4rem" />
      <div className="center-react-holder skill-card-holder" ref={divRef}>
        <PopUp
          heading={popupCard.heading}
          bodyText={popupCard.description}
          imgSrc={popupCard.img_src}
          imgAlt={popupCard.img_alt}
          ref={popUpRef}
          popUpDisplay={popUpDisplay}
          setPopUpDisplay={setPopUpDisplay}
          skill_list={popupCard.skill_list}
        />

        {cardDeck[deckKey].map((card_info, item) => {
          return (
            // <Collapse key={item}>
            <Card
            key={item}
              img_src={card_info.img_src}
              skill_list={card_info.skill_list}
              img_desc={card_info.img_desc}
              description={card_info.description}
              heading={card_info.heading}
              sectionRef={divRef}
              setPopUpCard={setPopUpCard}
              setPopUpDisplay={setPopUpDisplay}
              popUpRef={popUpRef}
            />

            // </Collapse>
          );
        })}

        {/* </TransitionGroup> */}
      </div>
      <SkillDeckButtons
        setDeckKey={setDeckKey}
        deckKey={deckKey}
        keys={Object.keys(cardDeck)}
        divRef={divRef}
      />
      <hr className="pageDivider" />
    </div>
  );
});
export default SkillSection;
