import React from "react";
function validateEmail(email){
  let re = /\S+@\S+\.\S+/;
  return re.test(email)
}
function ContactForm() {
  function sendMessage(){
    let proceed=true
    if (!validateEmail(formText.email)){proceed=false
      setTextError((prev)=>{return{...prev,email:true}})
    } 
    if (formText.name.length===0){proceed=false
      setTextError((prev)=>{return{...prev,name:true}})
    }
    if (formText.message.length===0){proceed=false
      setTextError((prev)=>{return{...prev,message:true}})
    }
    if (proceed){
    let data={}
    console.log(contactRefs)
    Object.keys(formText).forEach((key)=>
    data[key]=formText[key])
    // console.log(contactRefs)
    // Object.keys(contactRefs).forEach((key)=>
    // data[key]=contactRefs[key].current.value)
    
    // console.log(data)
    fetch("/send-message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    setSentMessage(true)
  }}
  
  const [formText,setFormText]=React.useState({name:'',email:'',message:''})
  const [textError,setTextError]=React.useState({name:false,email:false,message:false})
  const [sentMessage,setSentMessage]=React.useState(false)
  const contactRefs={name:React.useRef(),email:React.useRef(),body:React.useRef()}
  return (
    <div className="contact-form">
      <div className="contact-user-info-div">
        <input
          type="text"
          className={"contact-info"+(textError.name?' input-error':'')}
          id="contact-name"
          name="contact-name"
          placeholder="Name"
          style={{ marginRight: "0.5rem" }}
          value={formText.name}
          maxLength='200'
          onChange={(e)=> {let val=e.target.value; setFormText((prev)=>{return{...prev,name:val}});setTextError((prev)=>{return{...prev,name:false}});
          setSentMessage(false);}}
        ref={contactRefs.name}
          
        />
        <input
          type="email"
          className={"contact-info"+(textError.email?' input-error':'')}
          id="contact-email"
          name="contact-email"
          placeholder="Email"
          value={formText.email}
          style={{ marginLeft: "0.5rem" }}
          ref={contactRefs.email}
          maxLength='320'
          onChange={(e)=> {let val=e.target.value; setFormText((prev)=>{return{...prev,email:val}});
          setTextError((prev)=>{return{...prev,email:false}});
          setSentMessage(false);
        }}
          
        />
      </div>
      <textarea
        className={"big-text-area contact-body"+(textError.message?' input-error':'')}
        id="contact-body"
        name="contact-body"
        placeholder="Message"
        value={formText.message}
        ref={contactRefs.body}
        maxLength='5000'
        onChange={(e)=> {let val=e.target.value; setFormText((prev)=>{return{...prev,message:val}});
        setTextError((prev)=>{return{...prev,message:false}});
        setSentMessage(false)}}
      />
      {sentMessage?<button className="sent-button">Sent!</button>:<button onClick={sendMessage} className="submit-button">Send</button>}
    </div>
  );
}
export default ContactForm;
