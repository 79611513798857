const project_deck = [
  {
    img_src:
      'https://images.unsplash.com/photo-1547394765-185e1e68f34e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGNvbXB1dGVyJTIwa2V5Ym9hcmR8ZW58MHx8MHx8fDA%3D',
    img_desc: 'Computer Keyboard',
    heading: 'My Github Portfolio',
    url: 'https://github.com/AMPennings',
    description:
      'While this website offers a chance to showcase my design skills and demonstrate my proficiency with cloud-based software, it represents just a portion of my capabilities. A significant part of my expertise lies in utilizing Python for both data analysis and application development. My portfolio invites you to explore not only this web-based project but also a range of other projects that I pursue outside of it, giving insight into the diverse challenges that capture my interest.',
    skill_list: ['Communication', 'Writing', 'Introspection', 'React.js'],
  },
  {
    img_src:
      'https://imgs.search.brave.com/cFl7WGIwLNCySJCd6AD69QAopdvH9UaSW7mYIdFREr0/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5pc3RvY2twaG90/by5jb20vaWQvMTM1/MzQ3NTk4OS9waG90/by9jb3p5LXdpbmRv/dy1ub29rLXdpdGgt/b3Blbi1qb3VybmFs/LWFuZC1jb2ZmZWUu/anBnP3M9NjEyeDYx/MiZ3PTAmaz0yMCZj/PXdtb2xfcEN3YUpP/eE5XRVJHOTBpa3lN/Ujl4U1pYQzlvZFVO/dXUxWlM3UzQ9',
    img_desc: 'Desk with open journal on it',
    heading: 'My Personal Blog',
    url: '/my-blog',
    description:
      'I have recently developed a deep appreciation for the practice of writing down my thoughts and actions. This habit serves a dual purpose: it not only helps me improve my written communication skills, but also provides a valuable opportunity for self-reflection. By putting my thoughts into words, I am better able to examine the ideas I hold and the actions I am considering. It allows me to pause, take stock, and gain a deeper understanding of my motivations and decision-making process. As the philosopher Socrates once said, "The unexamined life is not worth living," and I find that this practice brings clarity and intentionality to my daily life."',
    skill_list: ['Communication', 'Writing', 'Introspection', 'React.js'],
  },
  {
    img_src: 'imgs/this-website.png',
    img_desc: 'Screenshot of this website',
    heading: 'This Website',
    url: '/this-website-explained',
    description:
      'This website serves as the centerpiece of the web-based portion of my portfolio. It provides a platform for presenting various cloud-based projects I am currently working on or have developed, while also showcasing the diverse range of my skills. It highlights my expertise in both front-end and back-end design, as well as my proficiency in cloud technologies. The website is hosted on AWS, with user interactions logged in an SQL database. This setup allows me to personalize the user experience and track which elements attract the most interest. Given that much of the traffic to this site comes from job-related inquiries, the custom URL I include on each resume helps me gain insights into what prospective employers are interested in, guiding me on areas where I can further upskill.',
    skill_list: ['Python', 'React.js', 'HTML'],
  },
]
export default project_deck
