import React from "react";
import SectionHeading from "./Section_heading.jsx";
import ContactForm from "./ContactForm.jsx";
import ContactDeets from "./ContactDeets.jsx";
const ContactSection = React.forwardRef((props, ref) => {
  return (
    <div id="contact-me-div" className="section-div" ref={ref}>
      <SectionHeading title="Contact Me" paddingTop="6rem" />
      <ContactForm />
      <ContactDeets />
    </div>
  );
});
export default ContactSection;
