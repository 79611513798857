import React from "react";
function NavBarDropDown(props) {
  let projects = [
    "My Blog",
  ];
  let projectUrls = [
    "my-blog",
  ];
  return (
    <div>
      <div
        className={
          "overlay-window" + (props.isCollapsed ? " drop-down-collapsed" : "")
        }
        onClick={() => {
          props.setIsCollapsed(true);
          // console.log("clicked");
        }}
      ></div>
      <div
        className={
          "drop-down" + (props.isCollapsed ? " drop-down-collapsed" : "")
        }
      >
        <div className={"drop-down-item"} style={{ paddingTop: "-1rem" }}>
          <a className="drop-down-link" href='/'>Home</a>
        </div>
        {projects.map((val, i) => {
          return (
            <div className={"drop-down-item"} key={i}>
              <a className="drop-down-link" href={`/${projectUrls[i]}`}>{val}</a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default NavBarDropDown;
