import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom' // Import Link for navigation
import TagHolder from './TagHolder'

import SectionHeading from './Section_heading.jsx'
function BlogList() {
  const [allPosts, setAllPosts] = useState([]) // stores all the posts
  const [visiblePosts, setVisiblePosts] = useState([]) // stores the posts currently visible
  const [count, setCount] = useState(10) // start with 10 posts initially
  const [loadingMore, setLoadingMore] = useState(false) // track if we are loading more posts

  let tagsRef = React.useRef()
  // Fetch posts once on mount and load only the first 10 posts initially
  useEffect(() => {
    fetch('/posts/index.json')
      .then((response) => response.json())
      .then((indexData) => {
        console.log('index')
        console.log(indexData)
        const postFiles = indexData.posts

        console.log(postFiles)
        // Fetch each post based on the filenames in the index
        const fetchPosts = async () => {
          const posts = await Promise.all(
            postFiles.map((filename) =>
              fetch(`/posts/${filename}`).then((res) => res.json())
            )
          )

          console.log(posts)
          setAllPosts(posts)
          setVisiblePosts(posts.slice(0, 10)) // Load the initial 10 posts (hardcoded)
        }

        fetchPosts()
      })
      .catch((error) => console.error('Error loading post index:', error))
  }, []) // No dependencies; only run on mount

  // Function to load more posts as the user scrolls
  const loadMorePosts = useCallback(() => {
    if (loadingMore || visiblePosts.length >= allPosts.length) return // Prevent loading if already loading or no more posts

    setLoadingMore(true)

    setTimeout(() => {
      const newCount = count + 10
      setVisiblePosts(allPosts.slice(0, newCount)) // Load the next set of posts
      setCount(newCount)
      setLoadingMore(false)
    }, 500) // Simulating async loading
  }, [loadingMore, visiblePosts.length, allPosts.length, count, allPosts])

  // Scroll event listener to detect when the user reaches the bottom of the page
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !loadingMore
      ) {
        loadMorePosts() // Load more posts when user reaches the bottom
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [loadingMore, loadMorePosts])

  // Helper to create snippets
  const createSnippet = (htmlContent, wordLimit) => {
    // Remove the content of <h1> and <h2> tags
    const cleanedHtml = htmlContent
      .replace(/<h1[^>]*>.*?<\/h1>/gi, '')
      .replace(/<h2[^>]*>.*?<\/h2>/gi, '')
    // Strip all remaining HTML tags

    const text = cleanedHtml.replace(/<[^>]*>?/gm, '') // Strip HTML tags
    const words = text.split(' ').slice(0, wordLimit).join(' ')
    return words + '...'
  }

  return (
    <div>
      <SectionHeading title="My Blog" paddingTop="8rem" />
      <div className="about-me-text-div">
        <p className="about-me-intro">
          Welcome to my blog! There aren't many posts yet, but I'm working on
          it! At the moment this is more about getting me writing, so I am not
          applying large amounts of quality control. That said I hope you still
          find something interesting or entertaining here.
        </p>
        <hr style={{ width: '50%', margin: '4rem auto 2rem' }} />
      </div>
      <div className="blog-list">
        {visiblePosts.map((post, index) => (
          <div key={index} className="blog-item">
            <h2>{post.title}</h2>
            <p>{createSnippet(post.content, 20)}</p>
            <TagHolder
              tags={post.tags}
              class="tag-holder"
              isCropped={false}
              ref={tagsRef}
            />
            <Link
              to={`/blog/${encodeURIComponent(post.filename)}`}
              className="project-button"
            >
              Read More
            </Link>
          </div>
        ))}
        {loadingMore && <p>Loading more posts...</p>}
      </div>
    </div>
  )
}

export default BlogList
