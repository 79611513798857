import React from "react";
import projectDeck from "../ProjectDeck.js";
import ProjectCard from "./ProjectCard.jsx";
import SectionHeading from "./Section_heading.jsx";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { FaChevronRight} from "@react-icons/all-files/fa/FaChevronRight";
let currentIndex = 0;
const ProjectsSection = React.forwardRef((props_head, ref_head) => {
  const refArray = React.useRef(projectDeck.map(() => React.createRef()));
  const projectHolderRef = React.useRef();
  let [stateHeight, setStateHeight] = React.useState(10);
  function shiftLeft() {
    currentIndex =
      currentIndex === 0
        ? projectDeck.length - 1
        : (currentIndex - 1) % projectDeck.length;
    updateCarousel();
  }
  function shiftRight() {
    currentIndex = (currentIndex + 1) % projectDeck.length;
    updateCarousel();
  }
  function updateCarousel() {
    refArray.current.forEach((ref, ind) => {
      ref.current.classList.remove("active");
      ref.current.classList.remove("inactive");
      ref.current.classList.remove("inactive-left");
      ref.current.classList.remove("inactive-right");
      if (ind === currentIndex) {
        ref.current.classList.add("active");
        
      } else if (
        ind === currentIndex - 1 ||
        (currentIndex === 0 && ind === projectDeck.length - 1)
      ) {
        ref.current.classList.add("inactive-left");
      } else if (
        ind === currentIndex + 1 ||
        (currentIndex === projectDeck.length && ind === 0)
      ) {
        ref.current.classList.add("inactive-right");
      } else {
        ref.current.classList.add("inactive");
      }
    });
    refArray.current[currentIndex].current.classList.add("active");
    refArray.current[
      currentIndex === 0 ? projectDeck.length - 1 : currentIndex - 1
    ].current.classList.add("inactive-left");
    refArray.current[
      currentIndex === projectDeck.length - 1 ? 0 : currentIndex + 1
    ].current.classList.add("inactive-right");
    updateHeight();
  }
  function updateHeight() {
    setStateHeight(refArray.current[currentIndex].current.offsetHeight + 200);
  }
  function handleResize() {
    refArray.current.forEach((ref) => {
      if (window.innerWidth > 1000) {
        ref.current.children[0].children[2].classList.remove(
          "project-text-clamp"
        );
      } else {
        ref.current.children[0].children[2].classList.add("project-text-clamp");
      }
    });
    updateHeight();
  }
  React.useEffect(() => {
    updateCarousel();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <div id="projects-div" className="section-div" ref={ref_head}>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <div
          className="project-card-holder"
          ref={projectHolderRef}
          style={{ height: `${stateHeight}px` }}
        >
          <span
            className="carousel-buttons"
            onClick={shiftLeft}
            style={{ top: "53%", left: "4%" }}
          >
            <FaChevronLeft />
          </span>
          <span
            className="carousel-buttons"
            onClick={shiftRight}
            style={{ top: "53%", right: "4%" }}
          >
            <FaChevronRight />
          </span>
          <SectionHeading title="My Projects" paddingTop="4rem" />

          {projectDeck.map((project, ind) => {
            return (
              <div ref={refArray.current[ind]} key={ind}>
                <ProjectCard
                  key={ind}
                  img_src={project.img_src}
                  img_desc={project.img_desc}
                  heading={project.heading}
                  description={project.description}
                  skill_list={project.skill_list}
                  url={project.url}
                />
              </div>
            );
          })}
        </div>
      </div>
      <hr className="pageDivider" />
    </div>
  );
});
export default ProjectsSection;
