import React, { forwardRef } from "react";
import NavBarDropDown from "./NavBarDropDown.jsx";
const NavBarOptions = forwardRef((prop, ref) => {
  // return <span onclick="window.location='{prop.loc}'" className="nav-location">{prop.text}</span>;
  return (
    <a href={prop.loc} className="nav-location" ref={ref}>
      {prop.text}
    </a>
  );
});
let navLocations = [
  { text: "About Me", loc: "/#about-me-div" },
  { text: "Skills", loc: "/#skills-div" },
  { text: "My Projects", loc: "/#projects-div" },
  { text: "Contact", loc: "/#contact-me-div" },
];
function NavBar(prop) {
  // This is the function that toggles the navbar view
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const refNavArray = React.useRef(navLocations.map(() => React.createRef()));
  const handleScroll = React.useCallback(() => {
    let currPos = window.pageYOffset;
    let pageWidth = window.innerHeight;
    let cumpos = 0;
    let maxOverlap = 0;
    let currentComponent = 0;
    let prevComponent = 0;
    if (prop.refArray != null){
    for (let i = 0; i < prop.refArray.length; i++) {
      // if currPos
      if (
        i > 0 &&
        refNavArray.current[i - 1].current.classList.contains(
          "nav-current-location"
        )
      ) {
        prevComponent = i;
      }
      let componentHeight = parseFloat(
        window
          .getComputedStyle(prop.refArray[i].current)
          .getPropertyValue("height")
          .slice(0, -2)
      );
      let componentTop = cumpos;
      let componentBottom = componentTop + componentHeight;
      let componentOnScreen =
        Math.min(currPos + pageWidth, componentBottom) -
        Math.max(componentTop, currPos);

      if (componentOnScreen > maxOverlap) {
        maxOverlap = componentOnScreen;
        currentComponent = i;
      }
      cumpos = componentBottom + 64;
      // let elementHeight =refArray[i].current.offsetHeight
      // if (cumpos<
    }
     if (prevComponent > 0 && prevComponent !== currentComponent) {
      refNavArray.current[prevComponent - 1].current.classList.remove(
        "nav-current-location"
      );
    }
    if (currentComponent > 0) {
      refNavArray.current[currentComponent - 1].current.classList.add(
        "nav-current-location"
      );
    }}
  },[prop.refArray])

  React.useEffect(
    () => window.addEventListener("scroll", () => handleScroll()),

    [handleScroll]
  );

  return (
    <div className="nav-bar">
      <a className="nav-logo" href='/'>A.Pennings </a>
      {(prop.refArray != null) ? navLocations.map((loc, iter) => {
        return (
          <NavBarOptions
            text={loc.text}
            loc={loc.loc}
            key={iter}
            ref={refNavArray.current[iter]}
          />
        );
      }):null}
      <span><NavBarDropDown
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <span className="nav-menu" onClick={() => {
              setIsCollapsed((prev) => {
                return !prev;
              });
            }}> ☰  </span></span>
        
        
    </div>
  );
}
export default NavBar;
