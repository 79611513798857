import React from "react";
import TagHolder from "./TagHolder";
const PopUp = React.forwardRef((props, ref) => {
  let scrollBarDivRef = React.useRef();
  let popUpContentDivRef = React.useRef();
  function closePopup() {
    // ref.current.style.display = "none";
    props.setPopUpDisplay("none");
  }
  function handleResize() {
    if (window.innerHeight * 0.8 > popUpContentDivRef.current.offsetHeight) {
      scrollBarDivRef.current.classList.remove("popup-box-scroll-bar-div");
      scrollBarDivRef.current.classList.add("popup-box-no-scroll-bar-div");
    } else {
      scrollBarDivRef.current.classList.add("popup-box-scroll-bar-div");
      scrollBarDivRef.current.classList.remove("popup-box-no-scroll-bar-div");
    }
  }
  React.useEffect(() => {
    handleResize();
    scrollBarDivRef.current.scroll({
      top: 0,
      behavior: "auto",
    });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.popUpDisplay]);
  return (
    <div
      className="popup-div"
      style={{ display: props.popUpDisplay }}
      ref={ref}
    >
      <div className="blurred-overlay" onClick={closePopup} />
      <div className="popup-box">
        <div className="popup-box-scroll-bar-div" ref={scrollBarDivRef}>
          <span className="close-span" onClick={closePopup}>
            &times;{" "}
          </span>
          <div ref={popUpContentDivRef}>
            <h1 className="popup-heading"> {props.heading}</h1>
            <div className="popup-body-div">
              <img
                className="popup-img"
                src={props.imgSrc}
                alt={props.imgAlt}
              />

              <p className="popup-body">{props.bodyText}</p>
              <TagHolder
                tags={props.skill_list}
                isCropped={false}
                class={"project-tag-holder"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default PopUp;
