import './styles.css'
import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import BlogList from './components/BlogList'
import BlogDetail from './components/BlogDetail'
import SkillsSection from './components/SkillSection.jsx'
import AboutMeSection from './components/AboutMeSection.jsx'
import HeaderSection from './components/HeaderSection.jsx'
import NavBar from './components/Navbar.jsx'
import ProjectsSection from './components/ProjectsSection.jsx'
import ContactSection from './components/ContactSection.jsx'

import ContactDeets from './components/ContactDeets.jsx'

export default function App() {
  const headerRef = React.useRef()
  const aboutMeRef = React.useRef()
  const skillsRef = React.useRef()
  const projectsRef = React.useRef()
  const contactRef = React.useRef()
  const refArray = [headerRef, aboutMeRef, skillsRef, projectsRef, contactRef]
  const globalRef = React.useRef()

  return (
    <Router>
      <div className="App" ref={globalRef}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeaderSection ref={headerRef} />
                <NavBar refArray={refArray} />
                <AboutMeSection ref={aboutMeRef} />
                <SkillsSection ref={skillsRef} />
                <ProjectsSection ref={projectsRef} />
                <ContactSection ref={contactRef} />
              </>
            }
          />
          <Route
            path="/my-blog"
            element={
              <>
                <NavBar refArray={null} />
                <BlogList />
                <ContactDeets />
              </>
            }
          />
          <Route
            path="/blog/:filename"
            element={
              <>
                <NavBar refArray={null} />
                <BlogDetail />
                <ContactDeets />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  )
}
