import React from "react";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
const HeaderSection = React.forwardRef((props, ref) => {
  return (
    <div className="header-section" ref={ref}>
      <h1 className="header-title" id="header-title-name">
        Allan Pennings{" "}
      </h1>
      <h1 className="header-title" id="header-title-researcher">
        Researcher
      </h1>
      <h1 className="header-title-special" id="header-title-programmer">
        Programmer
      </h1>
      <h1 className="header-title" id="header-title-scientist">
        Scientist
      </h1>
      <h1 id="header-down-arrows">
        <p className="header-arrows" id="chev-arrow1">
          <FaChevronDown />
        </p>
        <p className="header-arrows" id="chev-arrow2">
          <FaChevronDown />
        </p>
        <p className="header-arrows" id="chev-arrow3">
          <FaChevronDown />
        </p>
      </h1>
    </div>
  );
});
export default HeaderSection;
