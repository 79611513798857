import React from "react";
import SectionHeading from "./Section_heading.jsx";
let AboutMe = React.forwardRef((props, ref) => {
  return (
    <div id="about-me-div" className="section-div" ref={ref}>
  <SectionHeading title="About Me" paddingTop="8rem" />
  <div className="about-me-text-div">
    <p className="about-me-intro">
      Hi, and welcome to my website! Before I bore you with a
self-congralatory spiel, I invite you to skip this part and have fun
playing with some of the projects I am working on.
    </p>
    <hr style={{ width: "50%", margin: "4rem auto 2rem" }} />
    <div>
      <img
        src="./imgs/head_shot.jpg"
        style={{ float: "left" }}
        alt="Photo of me"
        className="headShotImg"
      />
      <p className="about-me-text" style={{ textAlign: "right" }}>
        My name is Allan, and I currently work as an Experimental Physicist at the University of Melbourne. However, my passions extend far beyond understanding the universe. <br />
        I am a hard-working, fast learner with a deep fascination for technology. Much of my spare time is spent writing programs and applications to further my understanding of computer science, automate routine tasks, and analyze data that interests me.
      </p>
    </div>
    <div>
      <img
        src="./imgs/unimelb-logo.png"
        alt="Melbourne University logo"
        style={{ float: "right" }}
        className="headShotImg"
      />
      <p className="about-me-text" style={{ textAlign: "left" }}>
        My passion for technology and science has been shaped throughout my education. I began my journey at the University of Melbourne, focusing on Mathematics and Physics, with a keen interest in computer science. For my postgraduate studies, I chose to delve deeper into practical work, pursuing a PhD in experimental physics at Swinburne University of Technology.
      </p>
    </div>
    <div>
      <img
        src="https://imgs.search.brave.com/4icHNiho-r_IYHIF1zeb4m9wLDvgSoR84z73F3iW59c/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9zd2luYnVybmUt/dW5pdmVyc2l0eS1v/Zi10ZWNobm9sb2d5/LnN2Zw"
        style={{ float: "left" }}
        alt="Swinburne University of Technology logo"
        className="headShotImg"
      />
      <p className="about-me-text" style={{ textAlign: "right" }}>
        A common misconception is that PhDs are just more years of classroom study, but in reality, they are more like research apprenticeships. During my four years as a PhD student, I spent my time as a research assistant in a lab, building state-of-the-art scientific equipment. This experience equipped me with a robust and future-focused skill set, from hands-on tasks like RF circuit design to computational challenges such as deploying machine learning algorithms.
      </p>
    </div>
    <div>
      <img
        src="./imgs/istockphoto-914309830-612x612.jpg"
        alt="Pathway symbol"
        style={{ float: "right" }}
        className="headShotImg"
      />
      <p className="about-me-text" style={{ textAlign: "left" }}>
        After completing my PhD, I returned to my alma mater as a research fellow at the University of Melbourne. Here, I continued work similar to my PhD but with increased responsibilities, including supervising my own PhD student. <br />
        With the end of my contract approaching, I am excited to take the next step in my career and focus even more on my passion for technology.
      </p>
    </div>
  </div>
  <hr className="pageDivider"/>
</div>

  );
});
export default AboutMe;
