import React from "react";
function keyToTxt(key) {
  let shownTxt = "";
  key.split("-").forEach((elem) => {
    shownTxt += elem.substring(0, 1).toUpperCase() + elem.substring(1) + " ";
  });
  // console.log(shownTxt);
  // return shownTxt;
  return shownTxt.slice(0, -1);
}
function SkillDeckButtons(props) {
  function changeSkills(key) {
    // console.log(key);
    // props.divRef.current.classList.add("collapes");
    
    let shownTxt = keyToTxt(key);
    props.setDeckKey(key);

    refArray.current.forEach((ref) => {
      ref.current.classList.remove("skill-button-active");
      if (ref.current.innerText === shownTxt) {
        ref.current.classList.add("skill-button-active");
        ref.current.classList.remove("skill-button-inactive");
      } else {
        ref.current.classList.add("skill-button-inactive");
      }
    });
  }
  const refArray = React.useRef(props.keys.map(() => React.createRef()));
  React.useEffect(() => {
    changeSkills(props.deckKey);
  }, []);
  return (
    <div className="center-react-holder" style={{ margin: "2rem auto 1rem" }}>
      {props.keys.map((key, iter) => (
        <SkillButton
          info={key}
          key={iter}
          ref={refArray.current[iter]}
          changeSkills={() => changeSkills(key)}
        />
      ))}
    </div>
  );
}
const SkillButton = React.forwardRef((props, ref) => {
  // console.log(props.info);
  let shownTxt = keyToTxt(props.info);
  return (
    <a
      ref={ref}
      href="#skills-div"
      className="skill-button"
      type="button"
      onClick={props.changeSkills}
    >
      {shownTxt}
    </a>
  );
});
export default SkillDeckButtons;
