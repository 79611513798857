import React from 'react'
import TagHolder from './TagHolder.jsx'
function formatDesciption(txt, characterNum) {
  if (characterNum === 0) {
    return ''
  } else {
    return txt.substring(0, characterNum) + '...'
  }
}

function Card(props) {
  let [cardBodyText, setCardBodyText] = React.useState(
    formatDesciption(props.description, 150)
  )
  let [styleHeader, setStyleHeader] = React.useState({
    fontSize: props.heading.length <= 15 ? '1.8rem' : '1.6rem',
  })
  let cardRef = React.useRef()
  let imgRef = React.useRef()
  let cardTextRef = React.useRef()
  let tagsRef = React.useRef()
  let popUpRef = React.useRef()
  function showPopUp() {
    props.setPopUpCard({
      heading: props.heading,
      description: props.description,
      img_src: props.img_src,
      img_desc: props.img_desc,
      ref: popUpRef,
      skill_list: props.skill_list,
    })

    props.setPopUpDisplay('block')
  }
  const handleResize = React.useCallback(() => {
    let bodyText
    if (window.innerWidth > 1000) {
      cardRef.current.classList.remove('skill-card-small')
      cardRef.current.classList.add('skill-card-big')
      setStyleHeader({
        fontSize: props.heading.length <= 15 ? '1.8rem' : '1.6rem',
      })
      cardTextRef.current.style.WebkitLineClamp =
        props.heading.length <= 15 ? 4 : 3
      bodyText = props.description
      tagsRef.current.style.display = 'flex'
      imgRef.current.style.height = '8rem'
    } else {
      cardRef.current.classList.remove('skill-card-big')
      cardRef.current.classList.add('skill-card-small')
      bodyText = formatDesciption(props.description, 0)
      setStyleHeader({
        fontSize: props.heading.split(' ').every((word) => word.length <= 8)
          ? '1.8rem'
          : props.heading.split(' ').every((word) => word.length <= 10)
          ? '1.4rem'
          : '1.2rem',
      })

      tagsRef.current.style.display = 'none'
      imgRef.current.style.height = '6.5rem'
    }
    setCardBodyText(bodyText)
  }, [props.description, props.heading])

  React.useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [cardBodyText, props.heading, handleResize])
  return (
    <div
      onClick={showPopUp}
      className="skill-card skill-card-big"
      ref={cardRef}
    >
      <img
        className="skill-logo"
        src={props.img_src}
        alt={props.img_desc}
        ref={imgRef}
      />
      {/* <div style={{ position: "absolute", top: "8.5rem" }}> */}
      <h2 className="skill-heading" style={styleHeader}>
        {props.heading}
      </h2>
      <p className="skill-body" ref={cardTextRef}>
        {cardBodyText}
      </p>
      {/* </div> */}
      <TagHolder
        tags={props.skill_list}
        class="tag-holder"
        isCropped={true}
        ref={tagsRef}
      />
    </div>
  )
}
export default Card
